import axios from 'axios';
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from 'react';
import { Dot, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useUser } from '../../../Context/UserContext';
import { useTheme } from '../../../Context/ThemeContext';
import ApiConfig from '../../../apiConfig';
import Down from '../../../assets/Arrows/triangle-down.png';
import Up from '../../../assets/Arrows/triangle-up.png';
import "./LineChart.css";
import AddCurrency from '../Modal/AddCurrency';
import AssetBoxClickModal from '../Modal/AssetBoxClickModal';
import AssetModalContent from '../Modal/AssetModalContent';
import AssetsContainer from './AssetsContainer';
import GenericModalButton from '../Modal/GenericModalButton';
import HomeOptionsButton from '../Modal/HomeOptionsButton';
import SuccessPopup from '../Loading/SuccessPopup';
import HomeLoading from '../Loading/HomeLoading';
import { useDevice } from '../../../Context/DeviceContext';
import FixedMenu from '../../FixedMenu/FixedMenu';
import HomeWebVersion from '../WebVersion/HomeWebVersion';
import AssetContainerWeb from '../WebVersion/AssetContainerWeb';


export default function LineChartV3({ setChartLoaded }) {
    const baseURL = ApiConfig.baseUrl;
    const apiKeys = ["CG-RC9nSmEBYzvHCMVfNAd81eN5", "CG-SdaQvVp7TH1Y2KJ85MfDaxLu", "CG-CPFogeuCJ78aFoA3KvpPSVsS", "CG-iFGMTVLJdxhTU5a8YSjm3Ctb", "CG-dR2TB1mD6cjvR7MKBjGAGENH"]
    const { userData: user } = useUser();
    let userData = JSON.stringify({
        "username": user.username,
        "password": user.password
    });
    const assetBoxOption = useRef("seitKaufRel")
    const [assetBoxOptionState, setBoxOptionState] = useState("seitKaufRel")
    const [showAssetModal, setShowAssetModal] = useState(false)

    const [initialMountState, setInitialMount] = useState(true)
    const initialMountRef = useRef(true)
    const {isMobile}= useDevice()
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(true)

    const [t2Loaded, setT2Loaded] = useState(false)
    const [t3Loaded, setT3Loaded] = useState(false)
    const [t4Loaded, setT4Loaded] = useState(false)
    const [t5Loaded, setT5Loaded]= useState(false)

    const [assetBoxesVar, setAssetBoxes] = useState()
    const [webAssetBoxesVar, setWebAssetBoxes] = useState()
    const [isPopupVisible, setPopupVisible] = useState(false);
    let popupText = useRef()

    const chartPositiveColor = "#2dd649"
    const chartNegativeColor = "#ff3030"

    const userStethReward = useRef()

    const breakWebSocket = useRef(true)
    let isNewUser = false

    const [loadingActions, setLoadingActions]= useState([])

    const [lastPrice, setLastPrice] = useState(() => {
        const lastPrice = localStorage.getItem("lastPrice")
        return lastPrice ? lastPrice : undefined
    });
    const rawLastPrice = useRef(localStorage.getItem("rawlastPrice"))
    const [toolTipDate, setTooltipDate] = useState(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
    const [showCursor, setShowCursor] = useState(false);
    const [animation, setAnimation] = useState(true)
    const [timeframe, setTimeframe] = useState(1);
    const timeFrameRef = useRef(1)
    const combinedDataRef = useRef()
    const [combinedData, setCombinedData] = useState();
    const [dynamicPnLAbs, setDynamicPnLAbs] = useState(() => {
        const absPnL = localStorage.getItem("absPnL")
        return absPnL ? absPnL : "0.00 €"
    })
    const [dynamicPnLRel, setDynamicPnLRel] = useState(() => {
        const relPnL = localStorage.getItem("relPnL")
        return relPnL ? relPnL : "0.00 %"
    })
    const [dynamicPnLColor, setDynPnLColor] = useState(() => {
        switch (localStorage.getItem("chartColor")) {
            case "#2dd649": { return "positive" }
            case "#ff3030": { return "negative" }
            default: { return "positive" }
        }
    })
    const [chartLineColor, setChartLineColor] = useState(() => {
        const chartColor = localStorage.getItem("chartColor")
        return chartColor
    })

    const isDarkTheme = useTheme()

    const componentStyles = {
        backgroundColor: isMobile ? '#000000' : '#0D0D0E',
        color: isDarkTheme ? '#FFFFFF' : '#000000',
        marginBottom: "96px",
        marginTop: "38px"
    };

    let groupedTransactionConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/groupedTransactions`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };


    let transactionConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/ordered`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };

    let nomValueConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/nomvalue`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: userData
      };



    let tickerApiConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/transactions/ownedAssetTicker`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };


    let assetPricesConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/crypto/assetPrices`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };

    let buyInConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/crypto/buyIns`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };

    function lidoDataConfig(address) {
        return {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://adlerbackend-adleranalytics.koyeb.app/api/crypto/lidoData/${address}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: userData
        };
    }

    function addLoadingAction(name) {
        setLoadingActions((prevLoadingActions) => [
          ...prevLoadingActions,
          { id: prevLoadingActions.length + 1, name: name, timeElapsed: 0, completed: false, error: false },
        ]);
      }
    
      function editLoadingAction(name, isComplete, isError){
          setLoadingActions((prevActions) =>
              prevActions.map((action, index) =>
                  (action.name === name) ? { ...action, completed: isComplete, error:isError }: action
              )
          )
      }



    async function getPortfolioValueGeneric() {
        try {
            const ethAdresses = [
                { name: "Bueno", address: "0x50cBc94CDcd883AF15163A39a98E7d06daDE4C56" },
                { name: "Tutti", address: "0x569eaa46d294874A1211d5c0Ac0FA59e65543FC2" },
                { name: "Ralle", address: "0xB95BD08D0759ed5eE40E221C2f338f8A11A56238" }
            ]

            async function fetchStakingRewards(address) {
                let response= null
                let data= null

                const lidoDataCache= JSON.parse(localStorage.getItem("LidoData"))
                if(lidoDataCache){
                    data= lidoDataCache
                }else{
                    response = await axios.request(lidoDataConfig(address))
                    if (response === null) { return 0 }
                    data = response.data
                    localStorage.setItem("LidoData", JSON.stringify(data))
                }
                
                

                const rewardsWei = data.totals.ethRewards
                const rewardsEth = rewardsWei * 10e-19
                userStethReward.current = (rewardsEth)

                localStorage.setItem("stethRewards", userStethReward.current)
                return rewardsEth;
            }

            switch (user.username) {
                case "Bueno": {addLoadingAction("Fetching Lido Data"); await fetchStakingRewards(ethAdresses[0].address); editLoadingAction("Fetching Lido Data", true, false); break; }
                case "Tutti": {addLoadingAction("Fetching Lido Data"); await fetchStakingRewards(ethAdresses[1].address); editLoadingAction("Fetching Lido Data", true, false); break; }
                case "Ralle": {addLoadingAction("Fetching Lido Data"); await fetchStakingRewards(ethAdresses[2].address); editLoadingAction("Fetching Lido Data", true, false); break; }
                default: {}
            }


            addLoadingAction("Fetching User Holdings")
            const response = await axios.request(groupedTransactionConfig);
            const transactions = response.data;
            if (response.data.length === 0) throw Error

            const userAmounts = []

            transactions.map((entry) => {
                if (entry.ticker === "STETH") {
                    if (userStethReward.current !== undefined) {
                        userAmounts.push({ ticker: entry.ticker, amount: entry.amount + userStethReward.current })
                    } else {
                        console.log("Lido Rewards not loaded")
                        togglePopup("Lido Rewards not loaded")
                        
                        userAmounts.push({ ticker: entry.ticker, amount: entry.amount })
                    }
                } else {
                    userAmounts.push({ ticker: entry.ticker, amount: entry.amount })
                }
            })

            editLoadingAction("Fetching User Holdings", true, false)

            localStorage.setItem("userAssetAmounts", JSON.stringify(userAmounts))

            addLoadingAction("Fetching Crypto Prices")
            const pricesResponse = await axios.request(assetPricesConfig)
            editLoadingAction("Fetching Crypto Prices", true, false)
            console.log("Price Refreshed via CMC API")


            addLoadingAction("Fetching Owned Assets")
            const ownedTicker = await axios.request(tickerApiConfig)
            editLoadingAction("Fetching Owned Assets", true, false)
            localStorage.setItem("ownedTickers", JSON.stringify(ownedTicker))
            const pricesMap = []


            for (let i = 0; i < ownedTicker.data.length; i++) {
                const currTicker = ownedTicker.data[i].toUpperCase()
                const blackListedTickers = ["BTC", "ETH", "STETH"]
                if (!initialMountRef.current && blackListedTickers.includes(currTicker)) return

                const cryptoPrice = pricesResponse.data.data[currTicker][0].quote.EUR.price;
                pricesMap.push({ ticker: currTicker, price: cryptoPrice })
            }
            localStorage.setItem("cryptoPrices", JSON.stringify(pricesMap))

            const portfolioValue = userAmounts.reduce((total, asset) => {
                // Find the price object for the current assets
                const priceObject = pricesMap.find(price => price.ticker === asset.ticker);
                if (priceObject) {
                    // Calculate the value of the current asset and add it to the total
                    return total + (asset.amount * priceObject.price);
                }
                // If the asset was not found in the prices array, just return the total so far
                return total;
            }, 0);

            const formattedValue = portfolioValue.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
            });

            rawLastPrice.current = parseFloat(portfolioValue.toFixed(2))
            setLastPrice(formattedValue)
            localStorage.setItem("rawLastPrice", rawLastPrice.current)
            localStorage.setItem("lastPrice", formattedValue)
            setTooltipDate(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
            localStorage.setItem("tooltipDate", formatDate(new Date(), 'dd.MM.yyyy HH:mm'))


            return portfolioValue;
        } catch (err) {
            rawLastPrice.current = 0.00
            setLastPrice("0.00 €")
            localStorage.setItem("rawLastPrice", rawLastPrice.current)
            localStorage.setItem("lastPrice", "0.00 €")
            setTooltipDate(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
            localStorage.setItem("tooltipDate", formatDate(new Date(), 'dd.MM.yyyy HH:mm'))

            isNewUser = true

            return rawLastPrice.current
        }
    }


    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const currentDate = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = ['Jan.', 'Feb.', 'März.', 'Apr.', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dez.'];
        const month = monthNames[date.getMonth()];
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const currentYear = currentDate.getFullYear();
        const dateYear = date.getFullYear();
    
        if (dateYear !== currentYear) {
            return `${day}. ${month} ${dateYear} · ${hours}:${minutes}`;
        } else {
            return `${day}. ${month} · ${hours}:${minutes}`;
        }
    }

    function dateToUnixTime(dateString) {
        const [date, time] = dateString.split(', ');
        const [day, month, year] = date.split('.');
        const [hours, minutes] = time.split(':');

        const unixTime = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`).getTime();

        return unixTime;
    }

    function getCurrentDate() {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const year = currentDate.getFullYear();

        return `${day}.${month}.${year}`;
    }

    function Sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }


    async function tickerToName(ticker) {
        const cache = JSON.parse(localStorage.getItem('tickerNameCache')) || {};

        if (cache[ticker]) {
            return cache[ticker];

        }else{
            const response = await axios.get(`${baseURL}/api/lib/${ticker}`);
            const fullName = response.data;
    
            cache[ticker] = fullName;
            
            localStorage.setItem('tickerNameCache', JSON.stringify(cache));
    
            return fullName;
        }
         
    }

    let currentKeyIndex = 0;
    function getNextApiKey() {
        const key = apiKeys[currentKeyIndex];
        currentKeyIndex = (currentKeyIndex + 1) % apiKeys.length;
        return key;
    }

    async function fetchMarketData(tickerName, timeframe1, ticker) {
        const apiKey = getNextApiKey()
        try {
            if(timeframe1==="MAX") return fetchMaxMarketData(ticker)
            const response = await axios.get(`https://api.coingecko.com/api/v3/coins/${tickerName}/market_chart?vs_currency=eur&days=${timeframe1}&precision=2&x_cg_demo_api_key=${apiKey}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching data for ${tickerName} with key ${apiKey}`);
            throw error; // Rethrowing the error for retry logic if needed
        }
    }

    async function fetchMaxMarketData(ticker){
       try{
        const end = Date.now()
        const start = ()=> {
            const timeDifference = Math.abs(end - localStorage.getItem("firstTransactionTimestamp"));
            const differenceInDays = timeDifference / (1000 * 60 * 60 * 24);
            return Math.ceil(differenceInDays);
        };

        const response=await axios.get(`https://min-api.cryptocompare.com/data/v2/histoday?fsym=${ticker}&tsym=EUR&toTs=${end}&limit=${start()}`)

        const data= {prices: response.data.Data.Data.map(item => [item.time*1000, item.open])}
        
        return data;

       }catch(err){
            console.log(err + " Max chart fetching went wrong. Trying to get saved Data")
       } 
    }


    async function calcPnLChartGeneric(timeframe1) {
        try {
            const fetchrawTransacs =async ()=>{
                const transactionsCache= localStorage.getItem("transactions")
                if(transactionsCache){
                    return JSON.parse(transactionsCache)
                }else{
                   const rawTransacs= await axios.request(transactionConfig)
                   localStorage.setItem("transactions", JSON.stringify(rawTransacs))
                   return rawTransacs
                }
            }
            addLoadingAction("Fetching Transactions")
            const rawTransac = await fetchrawTransacs()
            editLoadingAction("Fetching Transactions", true, false)

            const allTransactionsData = rawTransac.data
            localStorage.setItem("firstTransactionTimestamp", allTransactionsData[0].unixTime)
            if (allTransactionsData.length === 0) throw Error

            const ownedTicker = JSON.parse(localStorage.getItem("ownedTickers"))

            addLoadingAction("Fetching Chartdata")
            const marketData = ownedTicker.data.map(async (ticker) => {
                const tickerName = await tickerToName(ticker);
                // Retry mechanism or fallback logic could be added here

                const marketData = await fetchMarketData(tickerName, timeframe1, ticker);
                return {
                    ticker: ticker,
                    marketData: marketData,
                };
            });

            const assetDataMap = await Promise.all(marketData)
            editLoadingAction("Fetching Chartdata", true, false)

            const pricesAtTimeFrameStart = assetDataMap.map((entry) => {
                return { ticker: entry.ticker, price: entry.marketData.prices[0][1] }
            })

            switch (timeframe1) {
                case 1: { localStorage.setItem("T1PricesAtStart", JSON.stringify(pricesAtTimeFrameStart)); break; }
                case 7: { localStorage.setItem("T2PricesAtStart", JSON.stringify(pricesAtTimeFrameStart)); break; }
                case 31: { localStorage.setItem("T3PricesAtStart", JSON.stringify(pricesAtTimeFrameStart)); break; }
                case 365: { localStorage.setItem("T4PricesAtStart", JSON.stringify(pricesAtTimeFrameStart)); break; }
                case "MAX": { localStorage.setItem("T5PricesAtStart", JSON.stringify(pricesAtTimeFrameStart)); break; }
            }

            const ethAdresses = [
                { name: "Bueno", address: "0x50cBc94CDcd883AF15163A39a98E7d06daDE4C56" },
                { name: "Tutti", address: "0x569eaa46d294874A1211d5c0Ac0FA59e65543FC2" },
                { name: "Ralle", address: "0xB95BD08D0759ed5eE40E221C2f338f8A11A56238" }
            ]

            async function fetchStakingRewards(adress) {
                const data = JSON.parse(localStorage.getItem("LidoData"))
                if (data) {
                    const rewardsWei = data.totals.ethRewards
                    const rewardsEth = rewardsWei * 10e-19
                    for (const entry of data.events) {
                        const rewardsValue = parseFloat(entry.rewards);

                        if (!isNaN(rewardsValue)) {
                            allTransactionsData.push({
                                ticker: "STETH",
                                unixTime: entry.blockTime,
                                amount: rewardsValue * 10e-19
                            });
                        }
                    }
                }
            }


            switch (user.username) {
                case "Bueno": { await fetchStakingRewards(ethAdresses[0].address); break; }
                case "Tutti": { await fetchStakingRewards(ethAdresses[1].address); break; }
                case "Ralle": { await fetchStakingRewards(ethAdresses[2].address); break; }
            }


            addLoadingAction("Calculating Chart")
            const aggregatedData = assetDataMap.map((assetEntry) => {
                const ticker = assetEntry.ticker.toUpperCase();

                const assetPrices = assetEntry.marketData.prices;
                const tickerEntries = [];

                let currentAmount = 0;
                let transactionIndex = 0;

                for (let i = 0; i < assetPrices.length; i++) {
                    const histTimestamp = assetPrices[i][0];
                    const histPrice = assetPrices[i][1];

                    while (transactionIndex < allTransactionsData.length && allTransactionsData[transactionIndex].ticker !== ticker) {
                        transactionIndex++;
                    }

                    while (transactionIndex < allTransactionsData.length) {
                        const currentTrans = allTransactionsData[transactionIndex];
                        const currentTicker = currentTrans.ticker;
                        const currentTimestamp = currentTrans.unixTime;

                        if (currentTimestamp <= histTimestamp) {
                            if (currentTicker === ticker) {
                                currentAmount += currentTrans.amount;
                            }
                            transactionIndex++;
                        } else {
                            break;
                        }
                    }

                    tickerEntries.push({ ticker: ticker, timestamp: histTimestamp, price: histPrice * currentAmount, currAmount: currentAmount, date: formatDate(histTimestamp) });
                }

                return { ticker: ticker, data: tickerEntries };
            });


            let combinedData = [];


            let bound = aggregatedData.reduce((minLength, obj) => {
                return Math.min(minLength, obj.data.length);
            }, Infinity);


            // Iterate over each index in the data array
            for (let i = 0; i < bound; i++) {
                let timestamp = aggregatedData[0].data[i].timestamp;
                let date = aggregatedData[0].data[i].date;
                let totalPrice = 0;

                // Iterate over each entry in aggregatedData
                aggregatedData.forEach(entry => {
                    // Find the data object at the current index
                    var dataAtIndex = entry.data[i];
                    if (dataAtIndex) {
                        totalPrice += dataAtIndex.price; // Sum up the price for the current index
                    }
                });

                // Create combined entry for the current timestamp
                if(totalPrice>0){      
                    combinedData.push({
                        timestamp: timestamp,
                        date: date,
                        price: totalPrice
                    });
                }
            }

            editLoadingAction("Calculating Chart", true, false)
            return combinedData

        } catch (err) {
            console.log("Error Calculating Chart")
            if(timeframe1==="MAX"){
                return JSON.parse(await axios.post(`${baseURL}/api/cache/getCache/maxData`, JSON.parse(userData)))
            }else{
                return []
            }
            
        }
    }

    async function setAssetDataGeneric(timeframe) {

        const assetData = [];
        const t1Data = JSON.parse(localStorage.getItem("T1PricesAtStart"))
        const t2Data = JSON.parse(localStorage.getItem("T2PricesAtStart"))
        const t3Data = JSON.parse(localStorage.getItem("T3PricesAtStart"))
        const t4Data = JSON.parse(localStorage.getItem("T4PricesAtStart"))
        const t5Data = JSON.parse(localStorage.getItem("T5PricesAtStart"))

        
        const tickers = JSON.parse(localStorage.getItem("ownedTickers"))
        const fetchbuyIns =async ()=>{
            const buyInCache= localStorage.getItem("buyIns")
            if(buyInCache){
                return JSON.parse(buyInCache)
            }else{
                const buyIns = await axios.request(buyInConfig)
                localStorage.setItem("buyIns", JSON.stringify(buyIns))
                return buyIns
            }
        }
        const buyIns= await fetchbuyIns()

        const assetAmounts = JSON.parse(localStorage.getItem("userAssetAmounts"))
        const cryptoPrices = JSON.parse(localStorage.getItem("cryptoPrices"))



        switch (timeframe) {
            case 1: {
                tickers.data.map((ticker) => {
                    const asset = assetAmounts.find(asset => asset.ticker === ticker.toUpperCase())
                    const price = cryptoPrices.find(asset => asset.ticker === ticker.toUpperCase())
                    const priceAtTFrameStart = t1Data.find(asset => asset.ticker === ticker)
                    const assetValue = price.price * asset.amount
                    const assetPerformanceAbs = price.price - priceAtTFrameStart.price
                    const buyIn =buyIns?.data[ticker]
                    let absPerformance = null
                    let relativePerformance = null
                    if (buyIn) {
                        absPerformance = assetValue - buyIn * asset.amount
                        relativePerformance = (assetValue / (asset.amount * buyIn) - 1) * 100
                    }


                    assetData.push({
                        assetName: ticker.toUpperCase(),
                        amountOwned: asset.amount.toFixed(2),
                        assetValue: assetValue,
                        assetPerformance: (((price.price / priceAtTFrameStart.price) - 1) * 100).toFixed(2),
                        assetPerformanceAbs: assetPerformanceAbs,
                        buyIn: buyIn,
                        absPerformance: absPerformance,
                        relativePerformance: relativePerformance.toFixed(2),
                        priceAtTimeFrameStart: priceAtTFrameStart.price

                    })
                })
                break
            }

            case 7: {
                tickers.data.map((ticker) => {
                    const asset = assetAmounts.find(asset => asset.ticker === ticker.toUpperCase())
                    const price = cryptoPrices.find(asset => asset.ticker === ticker.toUpperCase())
                    const priceAtTFrameStart = t2Data.find(asset => asset.ticker === ticker)
                    const assetValue = price.price * asset.amount
                    const assetPerformanceAbs = price.price - priceAtTFrameStart.price
                    const buyIn = buyIns?.data[ticker]
                    let absPerformance = null
                    let relativePerformance = null
                    if (buyIn) {
                        absPerformance = assetValue - buyIn * asset.amount
                        relativePerformance = (assetValue / (asset.amount * buyIn) - 1) * 100
                    }

                    assetData.push({
                        assetName: ticker.toUpperCase(),
                        amountOwned: asset.amount.toFixed(2),
                        assetValue: assetValue,
                        assetPerformance: (((price.price / priceAtTFrameStart.price) - 1) * 100).toFixed(2),
                        assetPerformanceAbs: assetPerformanceAbs,
                        buyIn: buyIn,
                        absPerformance: absPerformance,
                        relativePerformance: relativePerformance.toFixed(2),
                        priceAtTimeFrameStart: priceAtTFrameStart.price

                    })
                })
                break
            }

            case 31: { 
                tickers.data.map((ticker) => {
                    const asset = assetAmounts.find(asset => asset.ticker === ticker.toUpperCase())
                    const price = cryptoPrices.find(asset => asset.ticker === ticker.toUpperCase())
                    const priceAtTFrameStart = t3Data.find(asset => asset.ticker === ticker)
                    const assetValue = price.price * asset.amount
                    const assetPerformanceAbs = price.price - priceAtTFrameStart.price
                    const buyIn = buyIns?.data[ticker]
                    let absPerformance = null
                    let relativePerformance = null
                    if (buyIn) {
                        absPerformance = assetValue - buyIn * asset.amount
                        relativePerformance = (assetValue / (asset.amount * buyIn) - 1) * 100
                    }

                    assetData.push({
                        assetName: ticker.toUpperCase(),
                        amountOwned: asset.amount.toFixed(2),
                        assetValue: assetValue,
                        assetPerformance: (((price.price / priceAtTFrameStart.price) - 1) * 100).toFixed(2),
                        assetPerformanceAbs: assetPerformanceAbs,
                        buyIn: buyIn,
                        absPerformance: absPerformance,
                        relativePerformance: relativePerformance.toFixed(2),
                        priceAtTimeFrameStart: priceAtTFrameStart.price

                    })
                })
                break
            }

            case 365: {
                tickers.data.map((ticker) => {
                    const asset = assetAmounts.find(asset => asset.ticker === ticker.toUpperCase())
                    const price = cryptoPrices.find(asset => asset.ticker === ticker.toUpperCase())
                    const priceAtTFrameStart = t4Data.find(asset => asset.ticker === ticker)
                    const assetValue = price.price * asset.amount
                    const assetPerformanceAbs = price.price - priceAtTFrameStart.price
                    const buyIn = buyIns?.data[ticker]
                    let absPerformance = null
                    let relativePerformance = null
                    if (buyIn) {
                        absPerformance = assetValue - buyIn * asset.amount
                        relativePerformance = (assetValue / (asset.amount * buyIn) - 1) * 100
                    }

                    assetData.push({
                        assetName: ticker.toUpperCase(),
                        amountOwned: asset.amount.toFixed(2),
                        assetValue: assetValue,
                        assetPerformance: (((price.price / priceAtTFrameStart.price) - 1) * 100).toFixed(2),
                        assetPerformanceAbs: assetPerformanceAbs,
                        buyIn: buyIn,
                        absPerformance: absPerformance,
                        relativePerformance: relativePerformance.toFixed(2),
                        priceAtTimeFrameStart: priceAtTFrameStart.price

                    })
                })
                break
            }

            case "MAX": {
                try{
                    tickers.data.map((ticker) => {
                        const asset = assetAmounts.find(asset => asset.ticker === ticker.toUpperCase())
                        const price = cryptoPrices.find(asset => asset.ticker === ticker.toUpperCase())
                        const priceAtTFrameStart = t5Data.find(asset => asset.ticker === ticker)
                        const assetValue = price.price * asset.amount
                        const assetPerformanceAbs = price.price - priceAtTFrameStart.price
                        const buyIn = buyIns?.data[ticker]
                        let absPerformance = null
                        let relativePerformance = null
                        if (buyIn) {
                            absPerformance = assetValue - buyIn * asset.amount
                            relativePerformance = (assetValue / (asset.amount * buyIn) - 1) * 100
                        }
    
                        assetData.push({
                            assetName: ticker.toUpperCase(),
                            amountOwned: asset.amount.toFixed(2),
                            assetValue: assetValue,
                            assetPerformance: (((price.price / priceAtTFrameStart.price) - 1) * 100).toFixed(2),
                            assetPerformanceAbs: assetPerformanceAbs,
                            buyIn: buyIn,
                            absPerformance: absPerformance,
                            relativePerformance: relativePerformance.toFixed(2),
                            priceAtTimeFrameStart: priceAtTFrameStart.price
    
                        })
                    })
                    break

                }catch(e){
                    console.log("AssetData could not be set")
                }
            }
        }

        assetData.sort((a, b) => b.assetValue - a.assetValue);

        switch (timeframe) {
            case 1: { localStorage.setItem("assetDataT1", JSON.stringify(assetData)); break; }
            case 7: { localStorage.setItem("assetDataT2", JSON.stringify(assetData)); break; }
            case 31: { localStorage.setItem("assetDataT3", JSON.stringify(assetData)); break; }
            case 365: { localStorage.setItem("assetDataT4", JSON.stringify(assetData)); break; }
            case "MAX": { localStorage.setItem("assetDataT5", JSON.stringify(assetData)); break; }
            default: {}
        }
    }

    /**______________WebSocketChanges___________________________________ */

    const fetchExchangeRate = async () => {
        const exchangeRateResponse = await axios.get("https://public.opendatasoft.com/api/explore/v2.1/catalog/datasets/euro-exchange-rates/records?select=*&where=currency%3D%22USD%22&order_by=date%20desc&limit=1&refine=date%3A%222024%22");
        const exchangeRate = Math.floor(exchangeRateResponse.data.results[0].rate * 100) / 100;
        const lastExchangeRateFetchTime = Date.now();
        return { exchangeRate, lastExchangeRateFetchTime };
    };

    const getExchangeRate = async () => {
        let exchangeRateData = JSON.parse(localStorage.getItem('exchangeRateData'));
        if (!exchangeRateData || (Date.now() - exchangeRateData.lastExchangeRateFetchTime) > 86400000) {
            exchangeRateData = await fetchExchangeRate();
            localStorage.setItem('exchangeRateData', JSON.stringify(exchangeRateData));
        }
        const exchangeRate = Math.floor(exchangeRateData.exchangeRate * 100) / 100;
        return exchangeRate.toFixed(2);
    };



    const handleWebSocketMessage = async (message) => {
        // const exchangeRate = await getExchangeRate();
        // const data = JSON.parse(message.data);
        // const ticker = data.data.s.replace("USDT", "")
        // const price = parseFloat(data.data.p) / exchangeRate;

        const data= JSON.parse(message.data)
        const ticker = ()=>{
            switch(data.symbol_id){
                case "COINBASE_SPOT_BTC_EUR": {return "BTC"}
                case "COINBASE_SPOT_ETH_EUR": {return "ETH"}
            }
        }
        const price = data.ask_price
        

        // Retrieve pricesMap and userAmounts from local storage
        const pricesMap = JSON.parse(localStorage.getItem("cryptoPrices")) || [];
        const userAmounts = JSON.parse(localStorage.getItem("userAssetAmounts")) || [];

        // Update the price field for the respective ticker in the pricesMap
        let updatedPricesMap = pricesMap.map(asset => {
            if (asset.ticker === ticker()) {
                return { ...asset, price };
            }
            return asset;
        });

        updatedPricesMap = updatedPricesMap.map(asset => {
            // Update the price for "STETH" to be the same as "ETH"
            if (asset.ticker === "STETH") {
                return { ...asset, price: pricesMap.find(entry => entry.ticker === "ETH").price };
            }
            return asset;
        });

        // Save the updated pricesMap back to local storage
        localStorage.setItem("cryptoPrices", JSON.stringify(updatedPricesMap));

        // Calculate the portfolio value using the updated pricesMap
        const portfolioValue = userAmounts.reduce((total, asset) => {
            // Find the price object for the current asset
            const priceObject = updatedPricesMap.find(price => price.ticker === asset.ticker);
            if (priceObject) {
                // Calculate the value of the current asset and add it to the total
                return total + (asset.amount * priceObject.price);
            }
            // If the asset was not found in the prices array, just return the total so far
            return total;
        }, 0);

        const formattedValue = portfolioValue.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
        });

        if (!breakWebSocket.current) {
            setLastPrice(formattedValue)
            rawLastPrice.current = portfolioValue
            localStorage.setItem("rawLastPrice", portfolioValue)
            localStorage.setItem("lastPrice", rawLastPrice.current.toLocaleString("de-DE", { style: "currency", currency: "EUR" }))
            resetDynamicPnLData()

            const chartData = JSON.parse(localStorage.getItem("chartDataT1"))

            const addedEntry = { timestamp: Date.now(), date: formatDate(new Date(), 'dd.MM.yyyy HH:mm'), price: portfolioValue, info: "self generated" }
            localStorage.setItem("chartDataT1", JSON.stringify([...chartData.slice(0, -1), addedEntry]))  //updating last entry of t1 chart
            if (timeFrameRef.current === 1) handleTimeframeChange(1)
        

            // _____________________________________________________________AssetContainer Changes_____________________________________________

            const assetDataArray = {
                T1: JSON.parse(localStorage.getItem("assetDataT1")),
                T2: JSON.parse(localStorage.getItem("assetDataT2")),
                T3: JSON.parse(localStorage.getItem("assetDataT3")),
                T4: JSON.parse(localStorage.getItem("assetDataT4")),
                T5: JSON.parse(localStorage.getItem("assetDataT5")),
            }

            for (const timeframe in assetDataArray) {
                const assetData = assetDataArray[timeframe];
                let updatedAssetData = assetData?.map(asset => {
                    if (asset.assetName === ticker()) {
                        const absValue= price * asset.amountOwned
                        const absPerformance= absValue - asset.buyIn * asset.amountOwned
                        const relativePerformance= ((price/asset.priceAtTimeFrameStart)-1)*100
                        const assetPerformanceAbs= price-asset.priceAtTimeFrameStart
                        const assetPerformance= ((price / asset.buyIn)-1) * 100
                        return { ...asset, assetValue: price * asset.amountOwned, absPerformance: absPerformance, assetPerformanceAbs: assetPerformanceAbs, assetPerformance: relativePerformance.toFixed(2), relativePerformance: assetPerformance.toFixed(2) };
                    }
                    return asset;
                });
                localStorage.setItem(`assetData${timeframe}`, JSON.stringify(updatedAssetData));

                if(ticker() === "ETH"){
                    updatedAssetData = updatedAssetData?.map(asset => {
                        if (asset.assetName === "STETH") {
                            const absValue= price * asset.amountOwned
                            const absPerformance= absValue - asset.buyIn * asset.amountOwned
                            const relativePerformance= ((price/asset.priceAtTimeFrameStart)-1)*100
                            const assetPerformanceAbs= price-asset.priceAtTimeFrameStart
                            const assetPerformance= ((price / asset.buyIn)-1) * 100
                            return { ...asset, assetValue: price * asset.amountOwned, absPerformance: absPerformance, assetPerformanceAbs: assetPerformanceAbs, assetPerformance: relativePerformance.toFixed(2), relativePerformance: assetPerformance.toFixed(2) };
                        }
                        return asset;
                    })
                }
                localStorage.setItem(`assetData${timeframe}`, JSON.stringify(updatedAssetData))
            }

            handleAssetContainerChange()
        }

        
        

    };
    

    useEffect(() => {
        let ws;
        const tickers = ["btc", "eth"]

        const connectToWebSocket = async () => {

            const postfix = "usdt@markPrice";
            const updatedTickers = tickers.map(ticker => ticker + postfix).join("/");

            ws = new WebSocket('wss://fstream.binance.com/stream?streams=' + updatedTickers);

            ws.onmessage = handleWebSocketMessage;

            ws.onopen = () => {
                console.log('WebSocket connected');
            };

            ws.onclose = () => {
                console.log('WebSocket disconnected');
            };

        };

        const connectToWebSocket2= async ()=>{
            ws = new WebSocket("wss://ws.coinapi.io/v1/");

            ws.onmessage = handleWebSocketMessage;

            ws.onopen = () => {
                console.log('WebSocket connected');
                ws.send(JSON.stringify({
                    "type": "hello",
                    "apikey": "97A3AF74-370F-4B26-9113-528A71372804",
                    "heartbeat": false,
                    "subscribe_data_type": ["quote"],
                    "subscribe_filter_asset_id": ["BTC/EUR", "ETH/EUR"],
                    "subscribe_filter_exchange_id": [
                        "COINBASE",
                    ],
                   "subscribe_update_limit_ms_quote":1000
                  }));
            }

            ws.onclose = () => {
                console.log('WebSocket disconnected');
            };
        }

        connectToWebSocket2();

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);


    /**__________________WebSocketChangesEnd_______________________________ */



    async function calcAllChartTimeframes(excludeT1) {
        try {
            console.log("recalculation of chart")
            //const chartDataT1 = excludeT1 ? 0 : await calcPnLChart(1)
            const chartDataT2 = await calcPnLChartGeneric(7)

            if (chartDataT2.length>0) {
                localStorage.setItem("chartDataT2", JSON.stringify(chartDataT2));
                await setAssetDataGeneric(7)
                setTimeFrameLoaded(7)
                console.log("Calculation of T2 successful");
            } else {
                console.log("Calculation of T2 failed trying again in 5s");
                await reFetchFailedChartData(7, 5000)
            }

            const chartDataT3 = await calcPnLChartGeneric(31)

            if (chartDataT3.length>0) {
                localStorage.setItem("chartDataT3", JSON.stringify(chartDataT3));
                await setAssetDataGeneric(31)
                setTimeFrameLoaded(31)
                console.log("Calculation of T3 successful");
            } else {
                console.log("Calculation of T3 failed trying again in 5s");
                await reFetchFailedChartData(31, 5000)
            }

            const chartDataT4 = await calcPnLChartGeneric(365)

            if (chartDataT4.length>0) {
                localStorage.setItem("chartDataT4", JSON.stringify(chartDataT4));
                await setAssetDataGeneric(365)
                setTimeFrameLoaded(365)
                console.log("Calculation of T4 successful");
            } else {
                console.log("Calculation of T4 failed trying again in 5s");
                await reFetchFailedChartData(365, 5000)
            }

            const chartDataT5= await calcPnLChartGeneric("MAX")

            if (chartDataT5.length>0) {
                localStorage.setItem("chartDataT5", JSON.stringify(chartDataT5));
                await setAssetDataGeneric("MAX")
                setTimeFrameLoaded("MAX")
                console.log("Calculation of T5 successful");

                //Caching Data in Backend
                axios.post(`${baseURL}/api/cache/setCache/maxData`, {
                    user: JSON.parse(userData),
                    cache: JSON.stringify(chartDataT5)
                })

            } else {
                console.log("Calculation of T5 failed trying again in 30s");
                await reFetchFailedChartData("MAX", 30000)
            }

        } catch (err) {

        }

    }

    async function reFetchFailedChartData(timeframe, delay) {
        
        await new Promise(resolve => setTimeout(resolve, delay));
        const timeFrameNumberMap = [{ timeframe: 1, name: "T1" }, { timeframe: 7, name: "T2" }, { timeframe: 31, name: "T3" }, { timeframe: 365, name: "T4" },{ timeframe: "MAX", name: "T5" }]
        const timeframeName = timeFrameNumberMap.find(entry => entry.timeframe === timeframe).name

        console.log("Refetching " + timeframeName)
        const chartData = await calcPnLChartGeneric(timeframe)
        
        if (chartData.length>0) {
            await setAssetDataGeneric(timeframe);
            setTimeFrameLoaded(timeframe)
            console.log(`Calculation of ${timeframeName} successful`)
        } else {
            console.log(`Calculation of ${timeframeName} failed | aborting fetch`)
        }
    }


    async function calcT1Chart() {
        const chartData = await calcPnLChartGeneric(1);

        if (chartData) {
            localStorage.setItem("chartDataT1", JSON.stringify(chartData));
            console.log("Calculation of T1 successful");
        } else {
            console.log("Calculation of T1 failed trying again in 40s");
        }

    }

    function handleTimeframeChange(timeframe, breakSocket) {

        switch (timeframe) {
            case 1: {
                if(breakSocket){breakWebSocket.current=true}
                setAnimation(true);
                handleAssetContainerChange(null, timeframe)

                let chartData = JSON.parse(localStorage.getItem("chartDataT1"))
                const timeStart = dateToUnixTime(`${getCurrentDate()}, 00:00`)

                let index = 0;
                for (const [i, entry] of chartData.entries()) {
                    if (entry.timestamp >= timeStart) {
                        index = i;
                        break;
                    } else {
                        index++;
                    }
                }
                const updatedChartData = chartData.slice(index);

                setCombinedData(updatedChartData);
                combinedDataRef.current = updatedChartData
                resetDynamicPnLData();
                if(breakSocket){setTimeout(() => {breakWebSocket.current=false},1000)}
                break;
            }
            case 7: {
                if(t2Loaded){
                    setAnimation(true);
                    handleAssetContainerChange(null, timeframe)
                    setCombinedData(JSON.parse(localStorage.getItem("chartDataT2")));
                    combinedDataRef.current = JSON.parse(localStorage.getItem("chartDataT2"));
                    resetDynamicPnLData();
                    break;
                }
                break
            }
            case 31: {
                if(t3Loaded){
                    setAnimation(true);
                    handleAssetContainerChange(null, timeframe)
                    setCombinedData(JSON.parse(localStorage.getItem("chartDataT3")));
                    combinedDataRef.current = JSON.parse(localStorage.getItem("chartDataT3"));
                    resetDynamicPnLData();
                    break;
                }
                break
            }
            case 365: {
                if(t4Loaded){
                    setAnimation(true);
                    handleAssetContainerChange(null, timeframe)
                    setCombinedData(JSON.parse(localStorage.getItem("chartDataT4")));
                    combinedDataRef.current = JSON.parse(localStorage.getItem("chartDataT4"));
                    resetDynamicPnLData();
                    break;
                }
                break
            }
            case "MAX": {
                if(t5Loaded){
                    setAnimation(true);
                    handleAssetContainerChange(null, timeframe)
                    setCombinedData(JSON.parse(localStorage.getItem("chartDataT5")));
                    combinedDataRef.current = JSON.parse(localStorage.getItem("chartDataT5"));
                    resetDynamicPnLData();
                    break;
                }
                break
            }

            default: {}
        }
    }


    const [mouseClicked, setMouseClicked]= useState(false)

    const handleMouseMove = (event) => {
        const chartRef = document.querySelector('.Chart-Container .recharts-wrapper');
        if (!chartRef || !mouseClicked) {
            return;
        }
        const dataIndex = event.activeTooltipIndex
        if (dataIndex >= 0 && dataIndex < combinedDataRef.current.length) {
            const price = combinedDataRef.current[dataIndex].price;
            setLastPrice(price);
            setTooltipDate(combinedDataRef.current[dataIndex].date);

            if(timeFrameRef.current==="MAX"){
                const nomVal= parseFloat(localStorage.getItem("nomVal"))
                let currPrice = price

                const absolute= currPrice - nomVal
                const relative= (currPrice/ nomVal-1) * 100
                if (absolute > 0) { setDynPnLColor("positive"); } else { setDynPnLColor("negative") }
                setDynamicPnLAbs(absolute.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
                setDynamicPnLRel(relative.toFixed(2))
            }else{
                const chartData = combinedDataRef.current
                let referencePrice = chartData[0].price
                let currPrice = price
                let totalPnL = currPrice - referencePrice
                let relativePnL = (currPrice / referencePrice - 1) * 100
                if (totalPnL > 0) { setDynPnLColor("positive"); } else { setDynPnLColor("negative") }
                setDynamicPnLAbs(totalPnL.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
                setDynamicPnLRel(relativePnL.toFixed(2))
            }
        }
    };

    const resetDynamicPnLData =async () => {
        try {

            if(timeFrameRef.current==="MAX"){
                
                const nomVal=parseFloat(localStorage.getItem("nomVal"))
                
                const currPrice = parseFloat(rawLastPrice.current)
                const absolute= currPrice- nomVal

                if (absolute > 0) { setDynPnLColor("positive"); setChartLineColor(chartPositiveColor); localStorage.setItem("chartColor", chartPositiveColor) } else { setDynPnLColor("negative"); setChartLineColor(chartNegativeColor); localStorage.setItem("chartColor", chartNegativeColor) }

                setDynamicPnLAbs(absolute.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
                localStorage.setItem("absPnL", absolute.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))

                const relative= (currPrice/nomVal-1) *100
                setDynamicPnLRel(relative.toFixed(2))
                localStorage.setItem("relPnL", relative.toFixed(2))

                setTooltipDate(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));

            }else{
                const chartData = combinedDataRef.current
                let referencePrice = chartData[0].price
                const currPrice = rawLastPrice.current
                let totalPnL = currPrice - referencePrice
                let relativePnL = (currPrice / referencePrice - 1) * 100
                if (totalPnL > 0) { setDynPnLColor("positive"); setChartLineColor(chartPositiveColor); localStorage.setItem("chartColor", chartPositiveColor) } else { setDynPnLColor("negative"); setChartLineColor(chartNegativeColor); localStorage.setItem("chartColor", chartNegativeColor) }
                setDynamicPnLAbs(totalPnL.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
                setDynamicPnLRel(relativePnL.toFixed(2))
                localStorage.setItem("absPnL", totalPnL.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
                localStorage.setItem("relPnL", relativePnL.toFixed(2))
                setTooltipDate(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
            }

            
            

        } catch (err) {
            setDynPnLColor("positive")
            setChartLineColor(chartPositiveColor)
            localStorage.setItem("chartColor", chartPositiveColor)
            setDynamicPnLAbs("0.00 €")
            setDynamicPnLRel("0.00")
            localStorage.setItem("absPnL", "0.00 €")
            localStorage.setItem("relPnL", 0.00)
            setTooltipDate(formatDate(new Date(), 'dd.MM.yyyy HH:mm'));
        }
    }

    function handleAssetPerformanceClick() {
        switch (assetBoxOption.current) {
            case "seitKaufAbs": handleAssetContainerChange("seitKaufRel"); setBoxOptionState("seitKaufRel"); break;
            case "seitKaufRel": handleAssetContainerChange("seitKaufAbs"); setBoxOptionState("seitKaufAbs"); break;
            case "TrendRel": handleAssetContainerChange("TrendAbs"); setBoxOptionState("TrendAbs"); break;
            case "TrendAbs": handleAssetContainerChange("TrendRel"); setBoxOptionState("TrendRel"); break;
        }
    }

    function toggleAssetModal(assetname) {
        setShowAssetModal(!showAssetModal)

        if (!showAssetModal) {
            setPageScrollable(false)
            buildModalContent(assetname)
        }
    }

    function setTimeFrameLoaded(timeframe) {
        switch (timeframe) {
            case 7: {
                setT2Loaded(true)
                break
            }

            case 31: {
                setT3Loaded(true)
                break
            }

            case 365: {
                setT4Loaded(true)
                break
            }

            case "MAX": {
                setT5Loaded(true)
                break
            }
        }
    }


    function handleAssetContainerChange(currentlyActive) {

        if (currentlyActive) assetBoxOption.current = currentlyActive

        let assetData
        switch (timeframe) {
            case 1: { assetData = JSON.parse(localStorage.getItem("assetDataT1")); break; }
            case 7: { assetData = JSON.parse(localStorage.getItem("assetDataT2")); break; }
            case 31: { assetData = JSON.parse(localStorage.getItem("assetDataT3")); break; }
            case 365: { assetData = JSON.parse(localStorage.getItem("assetDataT4")); break; }
            case "MAX": { assetData = JSON.parse(localStorage.getItem("assetDataT5")); break; }
        }


        if(!isMobile){
            const assetBoxes= assetData.map((asset, index) => (
                <AssetContainerWeb key={index} index={index} assetData={assetData[index]}></AssetContainerWeb>
            ))
            setWebAssetBoxes(assetBoxes)
        }

        switch (assetBoxOption.current) {
            case "seitKaufAbs": {
                const assetBoxes = assetData.map((asset, index) => (
                    <AssetsContainer parentOuterClickHandle={toggleAssetModal} parentClickHandle={handleAssetPerformanceClick} assetName={asset.assetName} amountOwned={asset.assetValue.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} assetValue={asset.assetValue} assetPerformance={asset.absPerformance} convertToCurrency={true} key={index} />
                ))
                setAssetBoxes(assetBoxes)
                
                break
            }

            case "seitKaufRel": {
                const assetBoxes = assetData.map((asset, index) => (
                    <AssetsContainer parentOuterClickHandle={toggleAssetModal} parentClickHandle={handleAssetPerformanceClick} assetName={asset.assetName} amountOwned={asset.assetValue.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} assetValue={asset.assetValue} assetPerformance={asset.relativePerformance} convertToCurrency={false} key={index} />
                ))
                setAssetBoxes(assetBoxes)
                break
            }

            case "TrendRel": {
                const assetBoxes = assetData.map((asset, index) => (
                    <AssetsContainer parentOuterClickHandle={toggleAssetModal} parentClickHandle={handleAssetPerformanceClick} assetName={asset.assetName} amountOwned={asset.amountOwned + " " + asset.assetName} assetValue={asset.assetValue} assetPerformance={asset.assetPerformance} convertToCurrency={false} key={index} />
                ))
                setAssetBoxes(assetBoxes)
                break
            }

            case "TrendAbs": {
                const assetBoxes = assetData.map((asset, index) => (
                    <AssetsContainer parentOuterClickHandle={toggleAssetModal} parentClickHandle={handleAssetPerformanceClick} assetName={asset.assetName} amountOwned={asset.amountOwned + " " + asset.assetName} assetValue={asset.assetValue} assetPerformance={asset.assetPerformanceAbs} convertToCurrency={true} key={index} />
                ))
                setAssetBoxes(assetBoxes)
                break
            }
        }
    }



    async function initialLoad() {
        await getPortfolioValueGeneric()
        if (!isNewUser) {
            const chartDataT1 = await calcPnLChartGeneric(1)
            await setAssetDataGeneric(1)

            if (chartDataT1) {
                localStorage.setItem("chartDataT1", JSON.stringify(chartDataT1));
                console.log("Calculation of T1 successful");
            } else {
                console.log("Calculation of T1 failed trying again in 40s at init load")
            }

            handleTimeframeChange(1)
            setShowLoadingAnimation(false)
            sessionStorage.setItem("didMount", true)
        } else {
            localStorage.setItem("chartDataT1", []);
            combinedDataRef.current = []
            setCombinedData([])
            setShowLoadingAnimation(false)
        }
    }

    async function cacheLoad() {
        const timeframes = [7, 31, 365, "MAX"]
        console.log("Running Cache Load")
        combinedDataRef.current = (JSON.parse(localStorage.getItem("chartDataT1")))
        rawLastPrice.current = JSON.parse(localStorage.getItem("rawLastPrice"))
        timeframes.forEach(frame => setTimeFrameLoaded(frame))

        resetDynamicPnLData()
        handleTimeframeChange(1)
        setShowLoadingAnimation(false)
    }

    async function reloadT1toT4() {
        setPopupVisible(true)
        popupText.current= "Calculating..."

        await calcT1Chart()
        await calcAllChartTimeframes(false)
        handleTimeframeChange(timeframe)


    }



    useEffect(() => {
        async function refresh() {
            await initialLoad()
            resetDynamicPnLData()
            if (!isNewUser) {
                await calcAllChartTimeframes(true)
                setInitialMount(false)
                initialMountRef.current = false
                breakWebSocket.current = false        //Allow Websocket changes

                const nomVal= await axios.request(nomValueConfig)
                localStorage.setItem("nomVal", nomVal.data)
            }
        }

        const sessionLoad = sessionStorage.getItem("didMount")
        if (sessionLoad === "false" || !sessionLoad) {
            refresh()
        } else {
            setInitialMount(false)
            initialMountRef.current = false
            cacheLoad()
        }

    }, [])


    useEffect(() => {
        setAnimation(false)
        async function geto() {
            handleTimeframeChange(timeframe, true)
        }
        if (!initialMountState) { geto() }                              // on inital mount use quickloading

        const reloadIntervall = setInterval(async () => {               //reload headline price every 20 seconds
            reloadData()
        }, 20000);

        const chartReloadIntervall = setInterval(() => {                // reload all timeframes except t1 every 5 minutes
            reloadChartData()
        }, 300000);

        const timeframe1ReloadIntervall = setInterval(() => {            // reload the t1 timeframe every 60 seconds
            calcT1Chart();
            handleTimeframeChange();
        }, 60000);

        async function reloadData() {
            await getPortfolioValueGeneric();
            resetDynamicPnLData()
        }

        async function reloadChartData() {
            await calcAllChartTimeframes(false)
            handleTimeframeChange(timeframe)
        }

        return () => {
            clearInterval(reloadIntervall);
            clearInterval(chartReloadIntervall);
            clearInterval(timeframe1ReloadIntervall);
        };

    }, [timeframe]);

    useEffect(() => {
        setTimeout(() => {
            setPopupVisible(false);
        }, 3000);
    }, [isPopupVisible]);

    const [showSurprise, setShowSurprise] = useState(false);
    const [showAddCurrencyModal, setShowAddCurrencyModal] = useState(false);
    const [pageScrollable, setPageScrollable] = useState(true)
    const [modalContent, setModalContent] = useState()
    const [modalChartTouch, setModalChartTouch] = useState(false)

    async function buildModalContent(ticker) {
        const userAmounts = JSON.parse(localStorage.getItem("userAssetAmounts"))
        const amountEntry = userAmounts.find(asset => asset.ticker === ticker)
        const amount = amountEntry.amount

        const cryptoPrices = JSON.parse(localStorage.getItem("cryptoPrices"))
        const cryptoPrice = cryptoPrices.find(asset => asset.ticker === ticker).price

        const assetData = JSON.parse(localStorage.getItem("assetDataT1"))
        const portfolioValue = JSON.parse(localStorage.getItem("rawLastPrice"))

        const buyIn = assetData.find(asset => asset.assetName === ticker).buyIn

        const positionValue = amount * cryptoPrice

        const absReturn = (positionValue) - (amount * buyIn)

        const performance = (positionValue / (amount * buyIn) - 1) * 100

        const allocation = positionValue / portfolioValue * 100


        const modalContent = (<AssetModalContent assetName={ticker} amount={amount} buyIn={buyIn} totalReturn={absReturn} totalValue={positionValue} performance={performance} allocation={allocation}></AssetModalContent>)

        setModalContent(modalContent)
    }

    function toggleModal() {
        setShowAddCurrencyModal(!showAddCurrencyModal)
    }

    function togglePageScrollable() {
        setPageScrollable(!pageScrollable)
    }

    function togglePopup(text){
        popupText.current= text
        setPopupVisible(true)
    }

    const mobileVersion = ()=>{
        return <div style={{ touchAction: `${!pageScrollable ? "none" : ""}` }}>
            <SuccessPopup isVisible={isPopupVisible} text={popupText.current} color={"green"} />
            {!showLoadingAnimation ? (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <div style={componentStyles}>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "-30px" }}>
                            {lastPrice ? (

                                <div>
                                    <h1 className={`account-value`}>
                                        {lastPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                    </h1>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={dynamicPnLColor === "negative" ? Down : Up} className='UpDownPic' />
                                        <p className={`tooltip-pnl ${dynamicPnLColor} account-pnl-values`}>
                                            {dynamicPnLAbs.replace("-", "")} ( {dynamicPnLRel.replace("-", "")}%)
                                        </p>
                                    </div>
                                    <p className="tooltip-date account-pnl-values">{toolTipDate}</p>

                                </div>
                            ) : (
                                <div>
                                    <h1 className={`account-value `}>
                                        0.00 €
                                    </h1>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={dynamicPnLColor === "negative" ? Down : Up} className='UpDownPic' />
                                        <p className={`tooltip-pnl ${dynamicPnLColor} account-pnl-values`}>
                                            {dynamicPnLAbs.replace("-", "")} ( 0.00%)
                                        </p>
                                    </div>
                                    <p className="tooltip-date account-pnl-values">{toolTipDate}</p>

                                </div>
                            )}
                        </div>


                        {combinedData.length > 0 ? (
                            <div>
                                <div className='Chart-Container marginGetter' style={{ touchAction: "none" }}>
                                    <ResponsiveContainer width="100%" height={300} >
                                        <LineChart width={300} height={100} data={combinedData} margin={{ right: 10, left: 5 }} onMouseMove={(event) => handleMouseMove(event)} onMouseUp={async () => { setShowCursor(false); setMouseClicked(false) ;breakWebSocket.current = false; setLastPrice(localStorage.getItem("lastPrice")); resetDynamicPnLData() }} onMouseDown={() => { setShowCursor(true);setMouseClicked(true) ;breakWebSocket.current = true }} >
                                            <XAxis dataKey="timestamp" hide type={timeframe === 1 ? "number" : "category"} domain={timeframe === 1 ? [dateToUnixTime(`${getCurrentDate()}, 00:00`), dateToUnixTime(`${getCurrentDate()}, 24:00`)] : ""} allowDataOverflow={timeframe === 1} />
                                            <YAxis dataKey={"price"} scale="auto" domain={['auto', 'auto']} hide />
                                            <Tooltip
                                                contentStyle={{ display: "none" }}
                                                cursor={showCursor ? true : ""}
                                            />
                                            <Line animationDuration={1000} stroke={chartLineColor} isAnimationActive={animation} type="monotone" dataKey="price" dot={false} strokeWidth={2} activeDot={false} />
                                            <ReferenceLine strokeWidth={.5} y={combinedData[0].price} stroke="white" strokeDasharray="3 4.2" />


                                        </LineChart>
                                    </ResponsiveContainer>

                                </div>


                                <div className='d-grid gap-2' style={{ display: 'flex !important', /*justifyContent: 'center' ,*/ alignItems: 'center !important', marginBottom: '20px', marginLeft: "20px", marginRight: "20px" }}>
                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" className="btn-check marginlr" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                                        <label onClick={() => { setTimeframe(1); timeFrameRef.current = 1 }} className={`btn btn-outline-primary1 mainPageButtonOutline button-loaded-text`} htmlFor="btnradio1" >1D</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(7); timeFrameRef.current = 7 }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t2Loaded ? "button-loaded-text" : ""}`} htmlFor="btnradio2" >1W</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(31); timeFrameRef.current = 31 }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t3Loaded ? "button-loaded-text" : ""}`} htmlFor="btnradio3" >1M</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(365); timeFrameRef.current = 365 }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t4Loaded ? "button-loaded-text" : ""}`} htmlFor="btnradio4" >1Y</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" />
                                        <label onClick={() => { setTimeframe("MAX"); timeFrameRef.current = "MAX" }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t5Loaded ? "button-loaded-text" : ""}`} htmlFor="btnradio5" >All</label>
                                    </div>
                                </div>


                                <div style={{ marginTop: "10px", marginBottom: "12px" }}>
                                    {<GenericModalButton mainText={"Investments"} toggleScrollFunction={togglePageScrollable} handleChangeInParent={handleAssetContainerChange} currentMetric={assetBoxOptionState} closingThreshold={120}></GenericModalButton>}
                                </div>


                                {showAddCurrencyModal && (
                                    <div className="modal">
                                        <div className="modal-content">
                                            <span className="close" onClick={() => setShowAddCurrencyModal(false)}></span>
                                            <AddCurrency toggleModal={toggleModal}></AddCurrency>

                                        </div>
                                    </div>
                                )}

                                {showAssetModal ? (<AssetBoxClickModal content={modalContent} modalVisible={showAssetModal} toggleVisibleStateInParent={toggleAssetModal} toggleScrollFunction={togglePageScrollable} ></AssetBoxClickModal>) : (<div></div>)}


                            </div>

                        ) : (<div></div>)}

                        <div>
                            {assetBoxesVar}
                        </div>


                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "20px", marginTop: "18px" }}>
                            <HomeOptionsButton popUpFunction={togglePopup} toggleScrollInParent={togglePageScrollable} homeReloadFunction={reloadT1toT4} />
                        </div>
                        <FixedMenu></FixedMenu>
                        
                    </div>
                </motion.div>) : (
                <div>
                    <HomeLoading actions={loadingActions}></HomeLoading>
                </div>
            )}
        </div>

    }

    const webVersion = ()=>{
        return <div>
            {!showLoadingAnimation ? (
                <div className='outer-grid'>
                <div style={{height:"55vh", backgroundColor:"#0d0d0e", borderRadius:"25px", overflowY:"hidden"}}>
                    <div className='topPart'>
                    <p className='partTitle'>Overview</p>

                        <div className='leftSide'>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}>

                                <div style={componentStyles}>

                                    <div className='webPriceContainer' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                        {lastPrice ? (
                                            <div>
                                                <h1 className={`account-value`}>
                                                    {lastPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                                </h1>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <img src={dynamicPnLColor === "negative" ? Down : Up} className='UpDownPic' />
                                                    <p className={`tooltip-pnl ${dynamicPnLColor} account-pnl-values`}>
                                                        {dynamicPnLAbs.replace("-", "")} ( {dynamicPnLRel.replace("-", "")}%)
                                                    </p>
                                                </div>
                                                <p className="tooltip-date account-pnl-values">{toolTipDate}</p>

                                            </div>
                                        ) : (
                                            <div>
                                                <h1 className={`account-value `}>
                                                    0.00 €
                                                </h1>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <img src={dynamicPnLColor === "negative" ? Down : Up} className='UpDownPic' />
                                                    <p className={`tooltip-pnl ${dynamicPnLColor} account-pnl-values`}>
                                                        {dynamicPnLAbs.replace("-", "")} ( 0.00%)
                                                    </p>
                                                </div>
                                                <p className="tooltip-date account-pnl-values">{toolTipDate}</p>

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </motion.div>

                            <div className='d-grid gap-2 webTimeframes'>
                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" className="btn-check marginlr" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                                        <label onClick={() => { setTimeframe(1); timeFrameRef.current = 1 }} className={`btn btn-outline-primary1 mainPageButtonOutline button-loaded-text webTimeFrameBorderColor`} htmlFor="btnradio1" >1D</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(7); timeFrameRef.current = 7 }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t2Loaded ? "button-loaded-text webTimeFrameBorderColor" : "webTimeFrameBorderColor"}`} htmlFor="btnradio2" >1W</label>

                                        <input  type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(31); timeFrameRef.current = 31 }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t3Loaded ? "button-loaded-text webTimeFrameBorderColor" : "webTimeFrameBorderColor"}`} htmlFor="btnradio3" >1M</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" />
                                        <label onClick={() => { setTimeframe(365); timeFrameRef.current = 365 }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t4Loaded ? "button-loaded-text webTimeFrameBorderColor" : "webTimeFrameBorderColor"}`} htmlFor="btnradio4" >1Y</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" />
                                        <label onClick={() => { setTimeframe("MAX"); timeFrameRef.current = "MAX" }} className={`btn btn-outline-primary1 mainPageButtonOutline ${t5Loaded ? "button-loaded-text webTimeFrameBorderColor" : "webTimeFrameBorderColor"}`} htmlFor="btnradio5" >All</label>
                                    </div>
                                </div>

                            
                        </div>

                        <div className='rightSide'>

                            <div className='Chart-Container marginGetter' style={{ touchAction: "none"}}>
                                    <ResponsiveContainer width="99%" height={220} >
                                        <LineChart width={300} height={100} data={combinedData} margin={{ right: 10, left: 5 }} onMouseMove={(event) => handleMouseMove(event)} onMouseUp={async () => { setShowCursor(false); setMouseClicked(false) ;breakWebSocket.current = false; setLastPrice(localStorage.getItem("lastPrice")); resetDynamicPnLData() }} onMouseDown={() => { setShowCursor(true);setMouseClicked(true) ;breakWebSocket.current = true }} >
                                            <XAxis dataKey="timestamp" hide type={timeframe === 1 ? "number" : "category"} domain={timeframe === 1 ? [dateToUnixTime(`${getCurrentDate()}, 00:00`), dateToUnixTime(`${getCurrentDate()}, 24:00`)] : ""} allowDataOverflow={timeframe === 1} />
                                            <YAxis scale="auto" domain={['auto', 'auto']} hide />
                                            <Tooltip
                                                contentStyle={{ display: "none" }}
                                                cursor={showCursor ? "crosshair" : false}
                                            />
                                            <Line animationDuration={1000} stroke={chartLineColor} isAnimationActive={animation} type="monotone" dataKey="price" dot={false} strokeWidth={2} activeDot={false} />
                                            <ReferenceLine strokeWidth={.5} y={combinedData[0].price} stroke="white" strokeDasharray="3 4.2" />


                                        </LineChart>
                                    </ResponsiveContainer>

                            </div>

    
                        </div>
                    </div>

                    <div className='lowerPart'>
        
                    </div>
                </div>


                <div style={{height:"55vh", backgroundColor:"#0d0d0e", borderRadius:"25px"}}>Der Adler</div> 

                <div style={{height:"30vh", backgroundColor:"#0d0d0e", borderRadius:"25px", overflowY:"scroll", scrollbarWidth: "none", msOverflowStyle: "none", scrollBehavior: "smooth" }}>
                    {webAssetBoxesVar}
                </div> 

                <div style={{height:"30vh", backgroundColor:"#0d0d0e", borderRadius:"25px"}}>Der Adler</div> 
            </div>
            ): (<HomeLoading actions={loadingActions}></HomeLoading>) }
        </div>
    }



    return (
        <div>
            {isMobile ? mobileVersion() : webVersion()}
        </div>
    )
}